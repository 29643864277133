import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

import HomeBanner from '../assets/img/home-banner.jpg';
import BitcoinIcon from '../assets/img/btc.svg';
import HelpIcon from '../assets/img/help.svg';
import FAQIcon from '../assets/img/faq.svg';
import LearnIcon from '../assets/img/learn.svg';
import SpotIcon from '../assets/img/spot.svg';
import BotIcon from '../assets/img/bot.svg';
import CopyTradeIcon from '../assets/img/copytrade.svg';
import LaunchpadIcon from '../assets/img/launchpad.svg';
import StakeIcon from '../assets/img/stake.svg';

const Home = () => {
	
	const { user } = useAuthContext();
	const [userdata, setUserData] = useState([]);
	const [productsData, setProductsData] = useState();

	useEffect(() => {


		const getUserData = async () => {
			const response = await fetch('https://api.cliftoneventstore.com/api/user/'+ user.username, {
				headers: {
					'Authorization': `Bearer ${user.token}`
				}
			});
			const json = await response.json();

			if (response.ok) {
				if (json.length === 1) {
					setUserData(json[0]);
					if (json[0].freezed) {
						localStorage.removeItem('byblt_logged_in');
						window.location.href= "/";
					}
				} else {
					console.log("Invalid user data: More than 1 result.")
				}
			}
		}

		const getProductsData = async () => {
			const response = await fetch('https://api.cliftoneventstore.com/api/product/active', {
				headers: {
					'Authorization': `Bearer ${user.token}`
				}
			});
			const json = await response.json();
			if (response.ok) {
				setProductsData(json);
			}
		}

		if (user) {
			getUserData();
			getProductsData();
		}

	}, []);

  	return (
		<>	
			
			{userdata != null ? (
				<>
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 p-0 home-banner">
								<img className="home-banner-img" src={HomeBanner}></img>
							</div>
						</div>
					</div>

					<div className="container mt-3">
						<div className="row">
							<div className="col-12 mt-3">
								<div className="page-container">
									<div className="row px-2">
										<div className="col-4 px-0 my-2 text-center">
											<Link to="/assets">
												<button className="btn py-0 w-100">
													<img className="custom-icon" src={SpotIcon}></img>
													<p className="icon-text mb-0">Assets</p>
												</button>
											</Link>
										</div>
										<div className="col-4 px-0 my-2 text-center">
											<Link to="/trade">
												<button className="btn py-0 w-100">
													<img className="custom-icon" src={CopyTradeIcon}></img>
													<p className="icon-text mb-0">Trade</p>
												</button>
											</Link>
										</div>
										<div className="col-4 px-0 my-2 text-center">
											<Link to="/earn">
												<button className="btn py-0 w-100">
													<img className="custom-icon" src={StakeIcon}></img>
													<p className="icon-text mb-0">Earn</p>
												</button>
											</Link>
										</div>
										{/* <div className="col-4 px-0 my-2 text-center">
											<Link to="">
												<button disabled className="btn py-0 w-100">
													<img className="custom-icon" src={BotIcon}></img>
													<p className="icon-text mb-0">Trading Bot<br></br>(Soon)</p>
												</button>
											</Link>
										</div>
										<div className="col-4 px-0 my-2 text-center">
											<Link to="#">
												<button disabled className="btn py-0 w-100">
													<img className="custom-icon" src={LaunchpadIcon}></img>
													<p className="icon-text mb-0">Launchpad<br></br>(Soon)</p>
												</button>
											</Link>
										</div> */}
									</div>
								</div>
							</div>
							{/* <div className="col-6 px-2 my-3">
								<div className="page-container">
									<Link to="/">
										<button className="btn py-0 w-100">
											<p className="mb-0"><i className="fa-duotone fa-arrow-down-to-bracket mr-2"></i>Deposit</p>
										</button>
									</Link>
								</div>
							</div>
							<div className="col-6 px-2 my-3">
								<div className="page-container">
									<Link to="/">
										<button className="btn py-0 w-100">
											<p className="mb-0"><i className="fa-duotone fa-arrow-up-from-bracket mr-2"></i>Withdraw</p>
										</button>
									</Link>
								</div>
							</div> */}
						</div>
					</div>

					<div className="container mt-5">
						<div className="row">
							<div className="col-lg-6 my-3">
								<h3>Top Gainers</h3>
								<div className="page-container">
									<div className="row px-2">
										<div className="col-12 my-2 px-2">
											<div className="page-sub-container">
												<div className="row">
													<div className="col-6 col-sm-4">
														<div className="d-flex justify-content-left">
															<img className="coin-icon" src={BitcoinIcon}></img>
															<h6 className="pair-name">BTCUSDT</h6>
														</div>
														<p className="coin-price mobile-sm mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-3 desktop-sm">
														<p className="coin-price mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-6 col-sm-5 text-right">
													<button className="btn coin-gain w-100">
														<p className="text-white mb-0">23.45%</p>
													</button>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 my-2 px-2">
											<div className="page-sub-container">
												<div className="row">
													<div className="col-6 col-sm-4">
														<div className="d-flex justify-content-left">
															<img className="coin-icon" src={BitcoinIcon}></img>
															<h6 className="pair-name">BTCUSDT</h6>
														</div>
														<p className="coin-price mobile-sm mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-3 desktop-sm">
														<p className="coin-price mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-6 col-sm-5 text-right">
													<button className="btn coin-gain w-100">
														<p className="text-white mb-0">23.45%</p>
													</button>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 my-2 px-2">
											<div className="page-sub-container">
												<div className="row">
													<div className="col-6 col-sm-4">
														<div className="d-flex justify-content-left">
															<img className="coin-icon" src={BitcoinIcon}></img>
															<h6 className="pair-name">BTCUSDT</h6>
														</div>
														<p className="coin-price mobile-sm mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-3 desktop-sm">
														<p className="coin-price mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-6 col-sm-5 text-right">
													<button className="btn coin-gain w-100">
														<p className="text-white mb-0">23.45%</p>
													</button>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 my-2 px-2">
											<div className="page-sub-container">
												<div className="row">
													<div className="col-6 col-sm-4">
														<div className="d-flex justify-content-left">
															<img className="coin-icon" src={BitcoinIcon}></img>
															<h6 className="pair-name">BTCUSDT</h6>
														</div>
														<p className="coin-price mobile-sm mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-3 desktop-sm">
														<p className="coin-price mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-6 col-sm-5 text-right">
													<button className="btn coin-gain w-100">
														<p className="text-white mb-0">23.45%</p>
													</button>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 my-2 px-2">
											<div className="page-sub-container">
												<div className="row">
													<div className="col-6 col-sm-4">
														<div className="d-flex justify-content-left">
															<img className="coin-icon" src={BitcoinIcon}></img>
															<h6 className="pair-name">BTCUSDT</h6>
														</div>
														<p className="coin-price mobile-sm mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-3 desktop-sm">
														<p className="coin-price mb-0"><strong>66,852.61</strong></p>
													</div>
													<div className="col-6 col-sm-5 text-right">
													<button className="btn coin-gain w-100">
														<p className="text-white mb-0">23.45%</p>
													</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6 my-3">
								<h3>Hot Trades</h3>
								<div className="page-container">
									<div className="row px-2">
										<div className="col-sm-6 col-xl-4 my-2 px-2">
											<div className="page-sub-container">
												<div className="d-flex justify-content-left">
													<img className="coin-icon" src={BitcoinIcon}></img>
													<h6 className="pair-name">BTCUSDT</h6>
												</div>
												<p className="coin-price mb-0">66,852.61</p>
											</div>
										</div>
										<div className="col-sm-6 col-xl-4 my-2 px-2">
											<div className="page-sub-container">
												<div className="d-flex justify-content-left">
													<img className="coin-icon" src={BitcoinIcon}></img>
													<h6 className="pair-name">BTCUSDT</h6>
												</div>
												<p className="coin-price mb-0">66,852.61</p>
											</div>
										</div>
										<div className="col-sm-6 col-xl-4 my-2 px-2">
											<div className="page-sub-container">
												<div className="d-flex justify-content-left">
													<img className="coin-icon" src={BitcoinIcon}></img>
													<h6 className="pair-name">BTCUSDT</h6>
												</div>
												<p className="coin-price mb-0">66,852.61</p>
											</div>
										</div>
										<div className="col-sm-6 col-xl-4 my-2 px-2">
											<div className="page-sub-container">
												<div className="d-flex justify-content-left">
													<img className="coin-icon" src={BitcoinIcon}></img>
													<h6 className="pair-name">BTCUSDT</h6>
												</div>
												<p className="coin-price mb-0">66,852.61</p>
											</div>
										</div>
									</div>
								</div>
								<button className="btn action-button mt-3" onClick={(e) => {
									window.location.href = "/trade";
								}}>
								<p className="my-1 mb-0"><strong>Start Trading Now<i className="fa-solid fa-arrow-right ml-3"></i></strong></p>
								</button>
							</div>
						</div>
					</div>

					<div className="container mt-5">
						<div className="row">
							<div className="col-12">
								<h3>Need Help?</h3>
							</div>
							<div className="col-12">
								<div className="page-container">
									<div className="row px-2">
										<div className="col-md-4 mt-3">
											<img className="custom-icon" src={HelpIcon}></img>
											<h5 className="mb-0">24/7 Customer Support</h5>
											<p className="">
												Get 24/7 support from our friendly Customer Support agents.
											</p>
										</div>
										<div className="col-md-4 mt-3">
											<img className="custom-icon" src={FAQIcon}></img>
											<h5 className="mb-0">FAQs</h5>
											<p className="">
												Explore FAQs for detailed instructions on specific features.
											</p>
										</div>
										<div className="col-md-4 mt-3">
											<img className="custom-icon" src={LearnIcon}></img>
											<h5 className="mb-0">Learn</h5>
											<p className="">
												Discover a range of trading guides tailored for beginners and advanced traders alike.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			):(
				<></>
			)}
		</>
	);

};

export default Home;