// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';

import ContactUsIcon from "./components/contact-us-icon";

// Pages
import Home from "./pages/home";
import Profile from "./pages/profile";
import Assets from "./pages/assets";
import Withdraw from "./pages/withdraw";
import Login from "./pages/login";
import Signup from "./pages/signup";

import Logotext from './assets/img/logotext.svg';

function App() {

  const { user } = useAuthContext();
  const [toggled, setToggled] = useState(false);
	const [userData, setUserData] = useState();

  const getUserData = async () => {
    const response = await fetch('https://bybltfuture.com/api/user/'+ user.username, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData(json[0]);
        if (json[0].freezed) {
          localStorage.removeItem('byblt_logged_in');
          window.location.href= "/";
        }
			} else {
				console.log("Invalid user data: More than 1 result.");
			}
    }
  }

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

  useEffect(() => {
		if (user) {
			getUserData();
		}
	}, []);

  return (
    <Router>
      <ContactUsIcon/>
      <section className="top-menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <NavLink to="/">
                <img className="mobile-header-logo" src={Logotext}></img>
              </NavLink>
            </div>
            <div className="col-3 text-right">
              <button className="btn px-0" onClick={(e) => {
                setToggled(!toggled);
              }}>
                <span className="top-menu-icon text-gray">
                  <i className="fa-sharp fa-light fa-bars"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <div className="d-flex">
        <Sidebar 
          transitionDuration={300}
          width="260px"
          backgroundColor="#17181E"
          breakPoint="all"
          rtl="true"
          onBackdropClick={() => setToggled(false)}
          toggled={toggled}
          rootStyles={{
            height: '100vh',
            zIndex: '110',
            border: 'none'
          }}
        >

          {user == null ? (
            <div className="px-3 mt-5 my-3">
              <button className="btn secondary-btn mt-3 w-100" onClick={(e) => {
                window.location.href = "/login";
              }}>
                <p className="text-gray mb-0"><strong>Log In</strong></p>
              </button>
              <button className="btn action-button mt-3 w-100" onClick={(e) => {
                window.location.href = "/signup";
              }}>
                <p className="mb-0"><strong>Sign Up</strong></p>
              </button>
            </div>
          ):(
            <></>
          )}



          <Menu
            renderExpandIcon={({ open }) => 
              <span className="collapse-icon">
                {open ? <i className="fa-duotone fa-caret-down mt-2"></i> : <i className="fa-duotone fa-caret-right mt-2"></i>}
              </span>
            }
            rootStyles={{
              ['.' + menuClasses.button]: {
                backgroundColor: '#17181E',
                color: '#DCDCDC',
                '&:hover': {
                  backgroundColor: '#25262B',
                },
              },
              ['.active']: {
                backgroundColor: '#25262B',

              }
            }}
          >

            <MenuItem 
              component={<NavLink to="/assets" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Assets</p>
            </MenuItem>

            <MenuItem 
              component={<NavLink to="/trade" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Trading</p>
            </MenuItem>

            <MenuItem 
              component={<NavLink to="/profile" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Settings</p>
            </MenuItem>
            
            {user != null ? (
              <MenuItem
                className="menu-item"
                onClick={handleLogout}
              > 
                <p className="side-menu-text">Logout</p>
              </MenuItem>
            ):(
              <></>
            )}

            <MenuItem 
              icon={<span className="side-menu-icon"><i className="fa-solid fa-money-bill-transfer"></i></span>}
              component={<NavLink to="/withdraw" activeClassName={['menu-item'].active}/>}
            > 
              <p className="side-menu-text">Withdraw</p>
            </MenuItem>





          </Menu>

        </Sidebar>

        <main className="main-content">

          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/login" element={!user ? <Login/>: <Navigate to="/" />}/>
            <Route path="/signup" element={!user ? <Signup/>: <Navigate to="/" />}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/assets" element={<Assets/>}/>
            <Route path="/withdraw" element={<Withdraw/>}/>
          </Routes>
        </main>
      </div>
      
    </Router>
  );
}

export default App;