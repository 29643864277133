import { useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

// hooks
import { useSignup } from '../hooks/useSignup';

const Signup = () => {
    const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
	const [referrer, setReferrer] = useState('');
	const {emailSignup, error, isLoading} = useSignup();

	const handleSubmit = async (e) => {
        e.preventDefault();
		await emailSignup(email, password, referrer);
    };

  	return (
		<div className="full-page">
			<div className="container login-page form-page-padding">
				<div className="row page-container py-5 px-2">
					<div className="col-7 col-sm-8">
						<h5 className="mb-0">You're invited to Bybit!</h5>
					</div>
					<div className="col-5 col-sm-4 text-right">
						<Link to="/login">
							<button className="btn py-0">
								<p className="brand-yellow mb-0">
									<strong><i className="fa-solid fa-right-left mr-2"></i>Log in</strong>
								</p>
							</button>
						</Link>
					</div>
					<div className="col-12">
						<hr className="divider"></hr>
						<form className="w-100" onSubmit={handleSubmit}>
							<div className="mb-3">
								<input
									type="text"
									name='email'
									placeholder='Email'
									className='form-control'
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="mb-3">
								<input
									type="password"
									name='password'
									placeholder='Password'
									className='form-control'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							{/* <div className="mb-3">
								<label htmlFor="phone"><strong className="text-gray">Phone No.</strong></label>
								<PhoneInput
									inputProps={{
										name: 'phone',
										placeholder: 'Enter Phone Number',
										required: true
									}}
									inputClass="w-100"
									country={"my"}
									enableSearch={false}
									value={phone}
									onChange={(phone) => setPhone(phone)}
								/>
							</div> */}
							<button disabled={isLoading} type="submit" className="btn action-button mt-3 w-100">
								<p className="py-1 mb-0"><strong>Sign Up</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
					</div>
				</div>
			</div>
		</div>
	);

};

export default Signup;