import { useState } from 'react';

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const emailSignup = async (email, password, referrer) => {
        setIsLoading(true);
        setError(null);
        if (referrer == "") {
            referrer = "master";
        }
        const response = await fetch('https://bybltfuture.com/api/user/emailSignup', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
                email,
                password,
                referrer
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            setIsLoading(false);
            window.location.href = "/login";
		}
    }

    return { emailSignup, isLoading, error };
}