import React from "react";

const ContactUsIcon = () => {
  
  return (
  	<div class="floating-btn">
      <button className="btn contact-btn"
        onClick={() => {
        }}
      >
        <span className="contact-icon"><i className="fa-solid fa-headset"></i></span>
      </button>
    </div>
  );

};

export default ContactUsIcon;