import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLogin } from '../hooks/useLogin';


const Login = () => {
    const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const {login, error, isLoading} = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(email, password);
	};

  return (
		<div className="full-page">
			<div className="container login-page form-page-padding">
				<div className="row page-container py-5 px-2">
					<div className="col-7 col-sm-8">
						<h5 className="mb-0">Welcome to Bybit</h5>
					</div>
					<div className="col-5 col-sm-4 text-right">
						<Link to="/signup">
							<button className="btn py-0">
								<p className="brand-yellow mb-0">
									<strong><i className="fa-solid fa-right-left mr-2"></i>Sign up</strong>
								</p>
							</button>
						</Link>
					</div>
					<div className="col-12">
						<hr className="divider"></hr>
						<form className="w-100" onSubmit={handleSubmit}>
							<div className="mb-3">
								<input
									type="text"
									placeholder='Email'
									autoComplete='off'
									name='email'
									className='form-control'
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="mb-3">
								<input
									type="password"
									placeholder='Password'
									name='password'
									className='form-control'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn action-button mt-3 w-100">
								<p className="py-1 mb-0"><strong>Log in</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
					</div>
				</div>
			</div>
		</div>
  );

};

export default Login;