import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useEditProfile } from '../hooks/useEditProfile';

const Assets = () => {

	const { user } = useAuthContext();
	const [userData, setUserData] = useState();
	const [newPhoneNo, setNewPhoneNo] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [bankName, setBankName] = useState('');
	const [accountNumber, setAccountNumber] = useState('');
	const [accountName, setAccountName] = useState('');

	const {editPhone, editPassword, editBankDetails, error, isLoading} = useEditProfile();

	const changePhoneNumber = async (e) => {
		e.preventDefault();
		await editPhone(user.token, userData._id, newPhoneNo);
	};

	const changePassword = async (e) => {
		e.preventDefault();
		console.log(newPassword);
		console.log(userData._id);
		await editPassword(user.token, userData._id, newPassword);
	};

	const changeBankDetails = async (e) => {
		e.preventDefault();
		await editBankDetails(user.token, userData._id, bankName, accountNumber, accountName);
	};

	const getUserData = async () => {
		const response = await fetch('https://bybltfuture.com/api/user/'+ user.username, {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				setUserData(json[0]);
				if (json[0].freezed) {
					localStorage.removeItem('byblt_logged_in');
					window.location.href= "/";
				}
			} else {
				console.log("Invalid user data: More than 1 result.")
			}
		}
	}

	useEffect(() => {

		if (user) {
			getUserData();
		}

	}, []);

  	return (
		<>
			{/* Edit Phone Number */}
			<div className="modal fade" id="editPhoneModal" tabindex="-1" role="dialog" aria-labelledby="editPhone" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Edit Contact Number</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						<div className="modal-body">
							<form className="w-100" onSubmit={changePhoneNumber}>
								<div className="mb-2">
									<label htmlFor="phone">
										<strong>New Contact Number</strong>
									</label>
									<input
										type="text"
										placeholder='Enter contact number'
										autoComplete='off'
										name='phone'
										className='form-control'
										onChange={(e) => setNewPhoneNo(e.target.value)}
									/>
								</div>
								<button disabled={isLoading} type="submit" className="btn action-button w-100">
									<h6 className="text-gray mb-0">Confirm</h6>
								</button>
								{error && <div className="error">{error}</div>}
							</form>
						</div>
					</div>
				</div>
			</div>

			{/* Change Password */}
			<div className="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="changePassword" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Change Password</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						<div className="modal-body">
							<form className="w-100" onSubmit={changePassword}>
								<div className="mb-2">
									<label htmlFor="newPW">
										<strong>New Password</strong>
									</label>
									<input
										type="password"
										placeholder='Enter new password'
										autoComplete='off'
										name='newPW'
										className='form-control'
										onChange={(e) => setNewPassword(e.target.value)}
									/>
								</div>
								<button disabled={isLoading} type="submit" className="btn action-button w-100">
									<h6 className="text-gray mb-0">Confirm</h6>
								</button>
								{error && <div className="error">{error}</div>}
							</form>
						</div>
					</div>
				</div>
			</div>

			{/* Change Bank Details */}
			<div className="modal fade" id="editBankDetailsModal" tabindex="-1" role="dialog" aria-labelledby="editBankDetails" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Manage Payment Account</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						<div className="modal-body">
							<form className="w-100" onSubmit={changeBankDetails}>
								<div className="mb-2">
									<label htmlFor="bankName">
										<strong>Bank Name</strong>
									</label>
									<input
										type="text"
										placeholder='Enter bank name'
										autoComplete='off'
										name='bankName'
										className='form-control'
										onChange={(e) => setBankName(e.target.value)}
									/>
								</div>
								<div className="mb-2">
									<label htmlFor="accountNumber">
										<strong>Account Number</strong>
									</label>
									<input
										type="text"
										placeholder='Enter account number'
										autoComplete='off'
										name='accountNumber'
										className='form-control'
										onChange={(e) => setAccountNumber(e.target.value)}
									/>
								</div>
								<div className="mb-2">
									<label htmlFor="accountName">
										<strong>Account Name</strong>
									</label>
									<input
										type="text"
										placeholder='Enter account name'
										autoComplete='off'
										name='accountName'
										className='form-control'
										onChange={(e) => setAccountName(e.target.value)}
									/>
								</div>
								<button disabled={isLoading} type="submit" className="btn action-button w-100">
									<h6 className="text-gray mb-0">Confirm</h6>
								</button>
								{error && <div className="error">{error}</div>}
							</form>
						</div>
					</div>
				</div>
			</div>

			{userData != null ? (
				<div className="container form-page-padding">
					<div className="row mt-3 mb-5">
						<div className="col-12">
							<h3 className="mb-0">Profile</h3>
						</div>
						<div className="col-12 px-2 mt-2">
							<div className="page-container mt-2 py-3">
								<h5 className="details-heading mb-0">Username</h5>
								<p className="mb-0">{userData.username}</p>
							</div>
							<div className="page-container mt-2 py-3">
								<h5 className="details-heading mb-0">Full Name</h5>
								<p className="mb-0">{userData.fullname}</p>
							</div>
							<div className="page-container mt-2 py-3">
								<h5 className="details-heading mb-0">Email</h5>
								<p className="mb-0">{userData.email}</p>
							</div>
							<div className="page-container mt-2 py-3">
								<h5 className="details-heading mb-0">Contact Number</h5>
								<p className="mb-0">{userData.phone}</p>
								<button className="btn action-button mt-2" data-toggle="modal" data-target="#editPhoneModal">
									<h6 className="text-gray mb-0">Edit</h6>
								</button>
							</div>
						</div>
					</div>

					<div className="row mt-3 mb-5">
						<div className="col-12">
							<h3 className="mb-0">Security</h3>
						</div>
						<div className="col-12 px-2 mt-2">
							<div className="page-container mt-2 py-3">
								<h5 className="details-heading mb-0">Account Password</h5>
								<p className="mb-0">******</p>
								<button className="btn action-button mt-2" data-toggle="modal" data-target="#changePasswordModal">
								<h6 className="text-gray mb-0">Change</h6>
								</button>
							</div>
						</div>
					</div>

					<div className="row mt-3 mb-5">
						<div className="col-12">
							<h3 className="mb-0">Payment Account</h3>
						</div>
						<div className="col-12 px-2 mt-2">
						{userData.bankName === '' || userData.accNumber === '' || userData.accName === '' ? (
							<button className="btn action-button mt-2 w-100" data-toggle="modal" data-target="#editBankDetailsModal">
								<p className="mb-0">+ Add Payment Account</p>
							</button>
						):(
							<div className="page-container mt-2 py-3">
								<h5 className="details-heading mb-0">{userData.bankName}</h5>
								<p className="mb-0">{userData.accNumber}</p>
								<p className="mb-0">{userData.accName}</p>
								<button className="btn action-button mt-2" data-toggle="modal" data-target="#editBankDetailsModal">
								<h6 className="text-gray mb-0">Change</h6>
								</button>
							</div>
						)}
						</div>
					</div>
				</div>
			):(
				<div>Assets Not logged in</div>
			)}
		</>
  );

};

export default Assets;